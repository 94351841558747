import { useEffect } from 'react';


import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SchoolIcon from '@mui/icons-material/School';
import {Logo} from './components/logo'
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import QuizIcon from '@mui/icons-material/Quiz';
import PhoneIcon from '@mui/icons-material/Phone';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import WorkIcon from '@mui/icons-material/Work';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import EditIcon from '@mui/icons-material/Edit';
import DvrIcon from '@mui/icons-material/Dvr';

import { NavItem } from './components/nav-item';

const items = [

  {
    href: '/scoreboard',
    icon: (<SportsScoreIcon fontSize="small" />),
    title: 'Scoreboard'
  },
  {
    href: '/challenges',
    icon: (<DvrIcon fontSize="small" />),
    title: 'Challenges'
  },
  {
    href: '/courses',
    icon: (<SchoolIcon fontSize="small" />),
    title: 'Courses'
  },
  {
    href: '/jobs',
    icon: (<WorkIcon fontSize="small" />),
    title: 'Browse jobs'
  },
  /*{
    href: '/rules',
    icon: (<DvrIcon fontSize="small" />),
    title: 'Rules'
  },*/
];
const sourcing_items = [

  {
    href: '/publishJob',
    icon: (<FiberNewIcon fontSize="small" />),
    title: 'Advertise a job'
  },
  {
    href: '/candidatesReport',
    icon: (<DvrIcon fontSize="small" />),
    title: 'Applies'
  }
];
const assesments_items = [

  {
    href: '/newTest',
    icon: (<NoteAddIcon fontSize="small" />),
    title: 'New private test'
  },
  {
    href: '/groups',
    icon: (<DvrIcon fontSize="small" />),
    title: 'Your tests'
  }
];
const items3 = [

  {
    href: '/rules',
    icon: (<SportsScoreIcon fontSize="small" />),
    title: 'Rules'
  },
  {
    href: '/feedback',
    icon: (<DvrIcon fontSize="small" />),
    title: 'Write a feedback'
  }
];
export const Sidebar = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });



  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>

          <div style={{paddingBottom:'15px',paddingRight:'3px'}}><img className="logo" src="/logo.png" onClick={()=>window.document.location="https://www.interway.ai"}/></div>
            
          </Box>

        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            marginBottom:'1em'
          }}
        />
            <NavItem
              key={"Home"}
              icon={<HomeIcon fontSize="small" />}
              href="/playground"
              title={"Home"}
            />
            <NavItem
              key={"New Home"}
              icon={<HomeIcon fontSize="small" />}
              href="/home"
              title={"New Home"}
            />
        <div style={{marginBottom:'1em'}} />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>

        <Divider
          sx={{
            borderColor: '#2D3748',
          }}
        />
        <div style={{textAlign:'left',padding:10,paddingLeft:28,paddingBottom:8}}><span style={{fontSize:14,fontWeight:500,textAlign:'left',color:'white'}}>For hiring</span></div>
        <div style={{textAlign:'left',padding:10,paddingLeft:28,paddingBottom:8}}><span style={{fontSize:12,fontWeight:400,textAlign:'left',color:'grey'}}>Sourcing</span></div>
        <Box sx={{ flexGrow: 1 }}>
          {sourcing_items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
        <div style={{textAlign:'left',padding:10,paddingLeft:28,paddingBottom:8}}><span style={{fontSize:12,fontWeight:400,textAlign:'left',color:'grey'}}>Assesments</span></div>
        <Box sx={{ flexGrow: 1 }}>
          {assesments_items.map((item) => (
            <NavItem
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>

        <div style={{marginBottom:'1em'}} />

        <Divider sx={{ borderColor: '#2D3748' }} />
        <Box
          sx={{
            px: 2,
            py: 3
          }}
        >
          <Typography
            color="neutral.100"
            variant="subtitle2"
          >
            Hiring?
          </Typography>
          <Typography
            color="neutral.500"
            variant="body2"
          >
            We build practical tests for companies.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              mt: 0,
              mx: 'auto',
              width: '160px',
              '& img': {
                width: '100%'
              }
            }}
          >

          </Box>

            <Button
              color="secondary"
              component="a"
              endIcon={(<OpenInNewIcon />)}
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
            >
              Contact Us
            </Button>

        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 250
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 250
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};