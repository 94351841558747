import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export const TotalUsers = ({total,up,title,iconType}) => (
  <Card>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
          >
            TOTAL {title}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {total}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: 'rgb(80, 72, 229)',
              height: 56,
              width: 56
            }}
          >
            {iconType ==0 && <PeopleIcon />}
            {iconType ==1 && <GroupsIcon />}
            {iconType ==2 && <EmojiEventsIcon />}
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          pt: 2
        }}
      >
        <ArrowUpwardIcon color="rgb(80, 72, 229)" />
        <Typography
          variant="body2"
          sx={{
            mr: 1
          }}
        >
          {up}%
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          Since last month
        </Typography>
      </Box>
    </CardContent>
  </Card>
);