
import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import {useParams} from "react-router-dom";
import Button from '@mui/material/Button';
import {
    TableSortLabel,CardHeader,
  } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import PublicIcon from '@mui/icons-material/Public';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {apiRequest} from "../../apis.js";
import {getCountryName} from "../../Countries.js";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
//import "./Playground.css";
import "./Scoreboard.css";
import {helpIcon,lockIcon} from "../../svgs.js";


export const DrawHomeScoreboard = (props) => {
    const [error, setError] = useState(null);
    const [scoreboard,setScoreboard] = useState([]);
    const [loading,setLoading] = useState(true);
    useEffect(() => {
      getScoreboard();
    },[]);
  
    const getScoreboard = () => {
      apiRequest("get","/apis.php?action=get_fast_scoreboard&page=homeplayground&token=play_&playground",{},{}, (res)=>{
                              setLoading(false);
                              if (!error) setError(res["error"] ?? null);
                              if ("obj" in res) {
                                  setScoreboard(res["obj"]);
                                  setLoading(false);
                              }
        });
    }
      return (
    <Card {...props} className="moreShadow">
      <CardHeader style={{padding:10}} title={<span><SportsScoreIcon /> Users scoreboard {loading && <CircularProgress />}</span>} />
  
        <Box>
          <Table>
            <TableHead>
              <TableRow style={{textAlign:'center'}}>
                <TableCell style={{textAlign:'center'}}>
                  #
                </TableCell>
                <TableCell align="center" className="tableTrFont">
                <div style={{textAlign:'left',paddingLeft:'30%'}}>User</div>
                </TableCell>
                <TableCell sortDirection="desc" style={{textAlign:'center'}} align="center" className="tableTrFont">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      <div style={{textAlign:'center'}}>Score</div>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
  
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreboard.slice(0,5).map((user,index) => (
                <TableRow
                  hover
                  key={index}
                >
                  <TableCell style={{textAlign:'center'}}>
                    {index+1}
                  </TableCell>
                  <TableCell align="center" >
                  <div style={{textAlign:'center',paddingLeft:'20%'}}>
                    <div style={{textAlign:'left'}}>
                  {user.country &&  (<span className="f32" style={{marginRight:'0.5em'}}>
                    <span style={{verticalAlign:'middle'}} className={"flag " + user.country.toLowerCase()} ></span>
                  </span>)}
                  <a href={`./profile/${user.you ? "" : user.user_id}`}>
                      <span style={{fontWeight:600,letterSpacing:'0.1em',fontSize:'1.2em',color:index==0?"rgb(80, 72, 229)":"rgb(80, 72, 229)"}} >{user.username} {user.username.startsWith("Anonymous") ? (<Tooltip title="This user hasn't signed in yet">{helpIcon()}</Tooltip>) : null}</span>
                      </a>
                  </div>
                  </div>
                  </TableCell>
  
                  <TableCell style={{textAlign:'center'}}>
              <span className="scoreFont" > {user.score}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
   
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="text"
          onClick={()=>document.location="/scoreboard"}
        >
          View all
        </Button>
      </Box>
    </Card>
  )
  }

  export const DrawHomeCountryBoard = () => {
    const [scoreboard,setScoreboard] = useState([]);
    const [loading,setScoreLoading] = useState(true);
    const [error,setError] = useState(false);
    let me = 0;
  
    useEffect(()=>{
      getDashboard();
    },[]);
    const getDashboard = () => {
      apiRequest("get","/apis.php?action=get_country_scoreboard&page=homeplayground&token=play_&playground",{},{}, (res)=>{
                              setScoreLoading(false);
                              if (!error) setError(res["error"] ?? null);
                              if ("obj" in res) {
                                  setScoreboard(res["obj"]);
                              }
        });
    }
  
      return (
        <Card className="moreShadow">
      <CardHeader style={{padding:10}} title={<span><PublicIcon/> Country scoreboard {loading && <CircularProgress />}</span>} />
  
        <Box>
          <Table>
            <TableHead>
              <TableRow style={{textAlign:'center'}}>
                <TableCell style={{textAlign:'center'}}>
                  #
                </TableCell>
                <TableCell align="center" className="tableTrFont">
                <div style={{textAlign:'left',paddingLeft:'30%'}}>User</div>
                </TableCell>
                <TableCell sortDirection="desc" style={{textAlign:'center'}} align="center" className="tableTrFont">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      <div style={{textAlign:'center'}}>Score</div>
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
  
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreboard.slice(0,5).map((user,index) => (
                <TableRow
                  hover
                  key={index}
                >
                  <TableCell style={{textAlign:'center'}}>
                    {index+1}
                  </TableCell>
                  <TableCell align="center" >
                  <div style={{textAlign:'center',paddingLeft:'20%'}}>
                    <div style={{textAlign:'left'}}>
                  {user.country &&  (<span className="f32" style={{marginRight:'0.5em'}}>
                    <span style={{verticalAlign:'middle'}} className={"flag " + user.country.toLowerCase()} ></span>
                  </span>)}
                  <span style={{fontSize:'1.2em',letterSpacing:'0.1em',fontWeight:600}}>{getCountryName(user.country)}</span>
                  </div>
                  </div>
                  </TableCell>
  
                  <TableCell style={{textAlign:'center'}}>
              <span className="scoreFont" > {user.score}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
   
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="text"
          onClick={()=>document.location="/scoreboard"}
        >
          View all
        </Button>
      </Box>
    </Card>
  )
  }
  