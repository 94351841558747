
import feedbacks from "./__mocks__/feedbacks.js";
import {country_scoreboard_data} from "./__mocks__/country_scoreboard_data.js";
import {scoreboard_data} from "./__mocks__/scoreboard_data.js";
import {last_ways_data} from "./__mocks__/last_ways_data.js";
import {challenge_info} from "./__mocks__/challenge_info.js";

export function apiRequest(method, url, getData,postBody, function_then, report_error = true) {

    let host = process.env.REACT_APP_API_ENDPOINT;
    if (url.includes("action=challenge_submit") || url.includes("action=resume_env")) {
        host = process.env.REACT_APP_API_SAND;
    }
    
    if (url.includes("country_scoreboard")) {
        function_then(country_scoreboard_data);
        return;
    }
    if (url.includes("scoreboard")) {
        function_then(scoreboard_data);
        return;
    }
    if (url.includes("last_ways")) {
        function_then(last_ways_data);
        return;
    }                 
    if (url.includes("get_challenge_info")) {
        function_then(challenge_info);
        return;
    }

    let res = null;

    let lastRes = null;
    const requestMetadata = {
        method: method=="postFile"?"post":method,
        credentials: 'include'
    }
        if (method == "post") {
            requestMetadata["body"] = JSON.stringify(postBody);
        }
        if (method == "postFile") {
            requestMetadata["body"] = postBody;
        } else {
            requestMetadata["headers"] = {'Content-Type': 'application/json'}
        }
    //console.log(requestMetadata);
    fetch(host+url, requestMetadata)
        .then(res => {lastRes = res; return /*res.json()*/ res.text()}).then((res) => {
            try {
                res = (JSON.parse(res));
            } catch {
                throw Error(res);
            }
            if (res["error"] && report_error) { 
                //apiRequest("post",`/apis.php?action=report_error`, {},{ error:res["error"] +  " url:" + url}, (res) => {},false);
            }
            if (!("obj" in res) && !("msg" in res) && !("error" in res) && report_error) { 
                apiRequest("post",`/apis.php?action=report_error`, {},{ error:"Missing obj/msg/error from server" +  " url:" + url}, (res) => {},false);
                if (!("error" in  res)) {
                    res["error"] = "Missing object from server. The error was reported to the admin."
                }
            }
            function_then(res);
        })
        .catch(error => {
            console.log(error);
            if (report_error) { 
                apiRequest("post",`/apis.php?action=report_error`, {},{ error:error +  " server_url:" + url + " window.location.href:"+window.location.href}, (res) => {},false);
            }

            console.log("error"); function_then({"error":"Error: connection lost. Please refresh the page. The error was reported to the support team.","error_info":url})
        });
  }
  