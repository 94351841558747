export const feedbacks = [
    {source:"Reddit, April 2022" ,
    url:"https://www.reddit.com/r/developersIndia/comments/tqvrs6/interactive_platform_for_learning_challenging/",
    text:"I absolutely love it, already solved two questions, will recommend this to everyone. Can you add more questions. I really think you have a winner here"},
    
    {source:"Reddit, April 2022",
    url:"https://www.reddit.com/r/developersIndia/comments/tqvrs6/interactive_platform_for_learning_challenging/",
    text:"Loved it! Never saw something like this before. Great Product! Keeping working on it! Would love to see more questions."},

    {source:"Recruiter in the Cyber industry, June 2022",
    url:"",
    text:"We operate in the cyber field and ask non- trivial questions during the frontal interview. Using Interway.AI, we could ask the same questions automatically, reach more candidates and shorten the time to hire."}
    ];