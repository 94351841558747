import React, { useState, useEffect,useRef } from "react";
import {useParams} from "react-router-dom";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {apiRequest} from "../../apis.js";
import ".//ChallengeMini.css";
import {backward} from "../../svgs.js";
const defaultOutput = ``

const computerIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-laptop" viewBox="0 0 16 16">
  <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"/>
</svg>)
}
export function ChallengeMini({agreed, challenge_name,token,refresh,onError}) {
  const [group, setGroup] = useState([]);
  const [challengeInfo, setChallengeInfo] = useState({});
  const [error, setError] = useState([]);
  const [commandsHistory, setCommandsHistory] = useState([]);
  const [historyCounter, setHistoryCounter] = useState(0);
  const [code, setCode] = useState("echo 'hello'");
  const [codeEditor, setCodeEditor] = useState(null);
  const [lastWay, setLastWay] = useState(null);
  const [codeOutput, setCodeOutput] = useState("");
  const [output, setOutput] = useState(defaultOutput);
  const [loading, setLoading] = useState(false);
  const [lang,setLang] = useState("node");
  const [open, setOpen] = React.useState(false);
  const [hash,setHash] = useState("");
  const [langs,setLangs] = useState([]);
  const [lastSubmit,setLastSubmit] = useState(null);
  const [firstSubmit,setFirstSubmit] = useState(true);
  const [interviewerObj,setInterviewerObj] = useState({});
  const [workingTime, setWorkingTime] = React.useState(null);
  const [solvedCurrent, setSolvedCurrent] = React.useState(false);
  const textInput = useRef(null);


  const handleOpen = () => setOpen(true);
  const challenge = "word_count";

  const [lastMessage,setLastMessage] = useState("");
  const [mobile,setMobile] = useState(true);



  const refresh_interviewer = () => {
    if (!firstSubmit) {
    }
  }
  useEffect(() => {
    getChallengeInfo();
    setLastSubmit(null);
    window.current_challenge_name = challenge_name;

  },[challenge_name]);
  const playGoodWork = () => {
    //getReport();
    handleOpen();
  }
  const add_message = (lastMessage) => {
    if (lastMessage) {
      lastMessage = lastMessage.replace(/\n/g,"\n-> ");
      if (lastMessage.includes("luck")) {
        setOutput(output => output.replace("You have a new mesasge from the virtual interviewer on the right.", "\n-> Avi (Your virtual assistant)---\n-> "+ lastMessage+". Type help if you need my help.\n"));
        //setOutput(codeOutput => codeOutput.replace("You have a new mesasge from the virtual interviewer on the right.", "\n->Avi (Your virtual assistant)---\n->"+ lastMessage+"\n->"));
      } else {
        setOutput(output =>  output + "\n\n-> Avi (Your virtual assistant)\n-> "+ lastMessage+"\n\n");
        //setOutput(codeOutput =>  codeOutput + "\n\n->Avi (Your virtual assistant)---\n->"+ lastMessage+"\n->");
      }
    }
  }
  useEffect(()=> {
    add_message(lastMessage);
  },[lastMessage]);
  useEffect(()=> {
    add_message(lastMessage);
  },[]);
  useEffect(() => {
    getChallengeInfo();
  },[agreed]);

  useEffect( () => {
    var objDiv = document.getElementById("pre");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  },[output]);

  const getChallengeInfo = (callback = null) => {
    if (!challenge_name) return;
    let dref = "";
    if ("document" in window && "referrer" in window.document) dref = window.document.referrer;
    apiRequest("post",`/apis.php?action=get_challenge_info&page=info_${challenge_name}&challenge=${challenge_name}&token=${token}`,{},{ref:dref}, (res)=>{
                            setError(res["error"] ?? null);
                            if ("obj" in res) {
                              //window.my_logs.push("getChallengeInfo : obj");
                              if (callback) callback();
                              let obj = res["obj"];
                              setChallengeInfo(res["obj"] ?? {});
                              setCommandsHistory(res["obj"]["history"] ?? []);
                              setLang(res["obj"]["lang"] ?? "node");
                              setLangs(res["obj"]["langs"] ?? ["node","python"]);
                              setLastWay("");
                              setHash(res["obj"]["user_hash"] ?? "");
                              //setLastSubmit(new Date());
                              if ("agreed" in res && res["agreed"]==true) apiRequest("post",`/apis_code.php?action=resume_env&challenge=${challenge_name}&token=${token}`,{},{hash:res["obj"]["user_hash"] ?? ""}, (res)=>{});
                              if (textInput.current) textInput.current.focus();
                              if ("last_way" in obj){
                                let last = obj["last_way"];
                                if (("text" in last) && ("output" in last)) {
                                  console.log("last way was setted to " + last["text"]);
                                  setLastWay(last["text"]);
                                  setCode(last["text"]);
                                  if (codeEditor) {
                                    codeEditor.setValue(last["text"]);
                                  }
                                  //setOutput(defaultOutput + "\nuser@Virtual-Machine:~$ echo Good luck! \nGood luck!" )
                                  if (mobile) {
                                    setOutput("\n-> Avi (Your virtual assistant)----\n-> "+res["obj"]["welcome"].replace(/\n/g,"\n-> ")+". Type help if you need my help.\n->\n\nuser@Virtual-Machine:~$ echo Good luck! \nGood luck!");
                                  } else {
                                    setOutput(defaultOutput + "\nuser@Virtual-Machine:~$ echo Good luck! \nGood luck!" );
                                  }
                                }
                              }
                            }
                            
                            console.log(res);
                              });
  }

  const handle_submit_response = (res)=> {
    refresh_interviewer();
    setLoading(false);
          setError(res["error"] ?? null);
          if (!("msg" in res)) {
            if (!("error" in res))  setError({error:"Error in challenge_submit: Missing msg from server"});
          } else {
            setOutput(output => output + "\n" + res["msg"]);
            
           
            refresh();
          }
  }
  const submit = () => {
    setLoading(true);
    setOutput(output =>  output + "\nuser@Virtual-Machine:~$ " + code)
    setCode("");

    if (firstSubmit) {
      apiRequest("post",`/apis.php?action=add_user_without_a&token=play_`,{},{},()=> {
        setFirstSubmit(false);
        getChallengeInfo(()=>{
          apiRequest("post",`/apis_code.php?action=challenge_submit&challenge=${challenge_name}&token=${token}`, {},{ch: challenge_name, input: code,hash:hash}, handle_submit_response);
        });
      });
    } else {
      apiRequest("post",`/apis_code.php?action=challenge_submit&challenge=${challenge_name}&token=${token}`, {},{ch: challenge_name, input: code,hash:hash}, handle_submit_response);
    }
    
        
     
  }

 

  useEffect( () =>{
    if (historyCounter > 0) {
      if (textInput.current) {
        textInput.current.focus();
        textInput.current.setSelectionRange(code.length,code.length);
      }
      
    }
  }, [code]);
  
  const enterPressed = (event) => {
    
    var keycode = event.keyCode || event.which;
    console.log(event);
    if(keycode === 13) { 
        setHistoryCounter(0);
        setCommandsHistory(commandsHistory => [...commandsHistory,code]);
        submit();
    } 

    if (keycode=== 38) {
      let index = commandsHistory.length - 1 - historyCounter;
      if (index >= 0) {
        setCode(commandsHistory[index]);
        textInput.current.focus();
        textInput.current.value = commandsHistory[index];
        textInput.current.setSelectionRange(commandsHistory[index].length,commandsHistory[index].length);
        setTimeout(() => {textInput.current.setSelectionRange(commandsHistory[index].length,commandsHistory[index].length);},100);
        //textInput.current.selectionStart = textInput.current.selectionEnd = textInput.current.value.length;
        setHistoryCounter(counter => counter+1);
      }
    }
    if (keycode=== 40) {
      let index = commandsHistory.length - 1 - historyCounter + 1;
      if (index >= 0) {
        setCode(commandsHistory[index]);
        textInput.current.focus();
        textInput.current.setSelectionRange(commandsHistory[index].length,commandsHistory[index].length);
        setTimeout(() => {textInput.current.setSelectionRange(commandsHistory[index].length,commandsHistory[index].length);},100);
        //textInput.current.selectionStart = textInput.current.selectionEnd = textInput.current.value.length;
        setHistoryCounter(counter => counter-1);
      }
    }


}

  const bashTemplate = () => {
    
    return (
      <div style={{height:'250px'}}>
        
        <div className="" style={{height:'100%', color:'white'}}>

            <div className="top-radius" style={{background:'black',background:'#2b2b2b',height:'20%',padding:5}}><b>Warm-Up: Can you print World without using W?</b> 
              <span className="floatRight" style={{marginRight:'1em'}}>{computerIcon()}</span>
            </div>

          <div className="card-body padding0" style={{height:'80%'}}>
              <div className="miniHomeBash" style={{height:'100%',background:'#2b2b2b',position:'relative'}}>
                  <pre className="miniPre" id="pre" style={{height:'80%'}}>
                  {output}
                  {error ? "\n\nError: "+error : null}
                  </pre>
                  <div className="mini_flex_space_between" >
                  
                  <input type="text" style={{padding:3}} autocomplete="off" ref={textInput} rows="1" name="code" id="input" value={code} onChange={(e)=>{
                      setCode(e.target.value);
                      setHistoryCounter(0);
                  }} onKeyDown={enterPressed}/>
                  
                
                  
                  <button id='Submit' className="btn btn-secondary btn-sm" onClick={submit}>Submit</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
    );
  }



  let foundTemplate = false;
  return (
    <div style={{height:'100%'}}>
    {challengeInfo["template"] == "bash_tmp" ? bashTemplate() : null}
    {(["bash_tmp"].includes(challengeInfo["template"] ?? "") === false) ? error : null}
    </div>
  )
}

export default ChallengeMini;
