import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Routes, Route, Link } from "react-router-dom";
import {Sidebar} from './Sidebar.js';
import {Navbar} from './components/Navbar.js';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DvrIcon from '@mui/icons-material/Dvr';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import LoginIcon from '@mui/icons-material/Login';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import { createTheme, ThemeProvider } from '@mui/material/styles';

import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import {theme} from './theme/index.js'

import {unionIcon} from './svgs.js';
import './Drawer.css';
const drawerWidth = 280;



export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

  export  function AccountMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{props.username ? props.username[0] : ""}</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

          {props.username && props.username.startsWith("Anonymous") ? (

            <div>
            <Link to="/login">
            <MenuItem>
            <ListItemIcon>
              <LoginIcon fontSize="small" />
            </ListItemIcon>
            Login
          </MenuItem>

            </Link>
            <Link to="/register">
            <MenuItem>
            <ListItemIcon>
              <AppRegistrationIcon fontSize="small" />
            </ListItemIcon>
            Register
          </MenuItem>

            </Link></div>

          ):(
            <React.Fragment>

    <Link to="/profile">
          <MenuItem>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            My profile
          </MenuItem>
          </Link>
          

      <Link to="/profile">
          <MenuItem>
            <ListItemIcon>
              <ModeEditIcon fontSize="small" />
            </ListItemIcon>
            Edit my profile
          </MenuItem>
          </Link>
          </React.Fragment>
          )}
         
       
          <Link to="/logout">
        <MenuItem>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Link>
        </Menu>

      </React.Fragment>
    );
  }
  const theme2 = createTheme({
    overrides: {
      MuiListItemIcon: {
        root: {
          minWidth: 40,
        },
      },
    },
  })

  const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    //flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 260
    }
  }));

  
export default function MiniDrawer(props) {
  //const theme = useTheme();
  const [open, setOpen] = React.useState(!props.demo);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isSidebarOpen, setSidebarOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>


   <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            //flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
         
        </Box>
      </DashboardLayoutRoot>

        <Navbar Username={props.Username} onSidebarOpen={() => setSidebarOpen(true)}/>
        <Sidebar onClose={() => setSidebarOpen(false)}  open={isSidebarOpen}/>
     

</ThemeProvider>
        </React.Fragment>
  );
}
