
import { Routes, Route, Link } from "react-router-dom";

import './App.css';

import {Playground} from './Playground.js';
import {Scoreboard} from './Scoreboard.js';
import {Home} from './pages/Home.js';
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from "react";
import MiniDrawer,{DrawerHeader} from "./Drawer"
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme2 = createTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
    typography: {
      subtitle: {
        lineHeight:'1em'
      },
      subtitle2: {
        lineHeight:'1em'
      }
    }
  },
})


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      console.log(window.my_logs);
      if (!window.my_logs) window.my_logs = [];
      
      // Error path
      return (
        <div>
          <h2>Sorry - something went wrong.</h2>
          <h4>The error was forwarded to the admin, and will be addressed soon as possible</h4>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
            <br />
            {window.my_logs.join(".").slice(-100)}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}
const listIconWidth = 37;
const opacity = 0.7;

const StyledList = styled(List)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'red',
    '&, & .MuiListItemIcon-root': {
      color: 'pink',
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'rgb(85 88 94)',
    '&, & .MuiListItemIcon-root': {
      color: 'yellow',
    },
  },
});

const PlaygroundLayout = (props) => {
  return (
  <Box sx={{ display: 'flex' }}>
    <ThemeProvider theme={theme2}>
  <MiniDrawer Demo={false} Username={props.username}>
  
  </MiniDrawer>

  <div style={{width:'100%',maxWidth:'1700px',margin:'auto'}}> <DrawerHeader />
  <div style={{marginBottom:'1em'}}>

  </div>{props.children}
  </div>
  </ThemeProvider>
  </Box>
  )
}
function App() {
  const [username,setUsername] = useState("Anonymous");

  const [isRec,setIsRec] = useState(false);
  const [error,setError] = useState(null);
  

  return (
    <div className="App">
            <Helmet htmlAttributes>
              <html lang="en" />
              <title>Interway.AI - guided challenges to discover your potential and boost your career</title>
              <meta name="description" content="Interway.AI combines challenges inspired by real interviews and jobs, with a virtual interviewer that accompanies you during the questions. It's more than just to code." />
      </Helmet>
      <ErrorBoundary>
      <Routes>
        <Route exact path="/" element={ <PlaygroundLayout username={username} path=""><Playground /></PlaygroundLayout>} />
        <Route path="/playground" element={
          <PlaygroundLayout username={username}  path=""><Playground /></PlaygroundLayout>
            } />
                  <Route path="/scoreboard" element={
          <PlaygroundLayout username={username} path=""><Scoreboard /></PlaygroundLayout>
            } />
                  <Route path="/home" element={
          <PlaygroundLayout username={username} isRec={isRec} path="home"><Home /></PlaygroundLayout>
        } />
       </Routes>

      </ErrorBoundary>
      
      </div>
  );
}

export default App;
