
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const pastTimeInStringMinutes = (time) => {
  if (!time) return "";
  let now = new Date().getTime();
  let user_time = new Date(time).getTime();
  if(!user_time) user_time =  new Date(time.replace(" ","T")).getTime();
  let  diff = now - user_time;
  let Difference_In_Days = Math.round(diff / (1000 * 3600 * 24));
  let Difference_In_Hours = Math.round(diff / (1000 * 3600));
  let Difference_In_Mintues = Math.round(diff / (1000 * 60));
  if (Difference_In_Mintues == 0) return (<div style={{display:'flex',justifyContent: 'center'}}><div>Right now</div> <div className="playCircle"></div></div>)
  if (Difference_In_Days > 0) {
    return `${Difference_In_Days} days ago`;
  }
  if (Difference_In_Hours > 0) {
    return `${Difference_In_Hours} hours ago`;
  }
  return `${Difference_In_Mintues} minutes ago`;
  

}

const LastWays_old = ({lastWays}) => {
  return (
    <table className="lastActiveTable" style={{marginTop:'0.5em'}}>
    <tbody>
    {lastWays.map((way,index) => (
      <tr key={index}><td style={{textAlign:'left'}}>
        <span style={{fontStyle:'italic',fontWeight:550}}>{way.correct && <EmojiEventsIcon />}
        
        <a href={"/profile/"+way.id}>{way.public_username}</a></span> 
        {way.challenge_name=="view_group" && (<span> is watching the scoreboard</span>) }
        {way.challenge_name!="view_group" && !way.correct && (<span> is trying to solve <span style={{fontStyle:'italic'}}>{way.challenge_name}</span></span>) }
        {way.challenge_name!="view_group" && way.correct && (<span> solved <span style={{fontStyle:'italic'}}>{way.challenge_name}</span></span>) }
      </td>
      <td>{pastTimeInStringMinutes(way.time)}</td></tr>))}
    </tbody></table>
  )
}

export const LastWays = ({ways}) => (
  <Card>
    <CardHeader
      subtitle={`${ways.length} in total`}
      title="Latest Activities"
    />
    <Divider />
    <List>
      {ways.slice(0,5).map((way, i) => (
        <ListItem
          divider={i < ways.length - 1}
          key={LastWays.id}
        >
          <ListItemAvatar>
            <EmojiEventsIcon />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div>
                <a href={"/profile/"+way.id}><span style={{color:'rgb(80, 72, 229)'}}>{way.public_username}</span></a>
              {way.challenge_name=="view_group" && (<span> is watching the scoreboard</span>) }
              {way.challenge_name!="view_group" && !way.correct && (<span> is trying to solve <span style={{fontStyle:'italic'}}>{way.challenge_name}</span></span>) }
              {way.challenge_name!="view_group" && way.correct && (<span> solved <span style={{fontStyle:'italic'}}>{way.challenge_name}</span></span>) }
            </div>
            
            }
            secondary={pastTimeInStringMinutes(way.time)}
          />

        </ListItem>
      ))}
    </List>
    <Divider />

  </Card>
);