import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Routes, Route, Link } from "react-router-dom";
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#ffffffa3',
  boxShadow: theme.shadows[3]
}));

export const Navbar = (props) => {
  const { onSidebarOpen, ...other } = props;

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 250
          },
          width: {
            lg: 'calc(100% - 250px)'
          },
          
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
            backgroundColor:'#ffffffa3 !important'
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Avatar
            sx={{
              height: 40,
              width: 40,
              ml: 1
            }}
            src="/static/images/avatars/avatar_1.png"
          >
            <ModeEditIcon fontSize="small" />
          </Avatar>
          <span style={{marginLeft:'0.5em',color:'white'}}>{props.Username && props.Username.startsWith("Anonymous") ? <Link to="/login"><span className="loginRegisterLink">Login / Register</span></Link> : <span className="loginRegisterLink">{props.Username}</span>}</span>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
}
Navbar.propTypes = {
  onSidebarOpen: PropTypes.func
};