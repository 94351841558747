
import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import {useParams} from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import Tooltip from '@mui/material/Tooltip';

import TextField from '@mui/material/TextField';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {Container, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import {
  TableSortLabel,CardHeader,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import TableContainer from '@mui/material/TableContainer';
import Radio from '@mui/material/Radio';
import KeyIcon from '@mui/icons-material/Key';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TableHead from '@mui/material/TableHead';
import BugReportIcon from '@mui/icons-material/BugReport';
import TableRow from '@mui/material/TableRow';
import AbcIcon from '@mui/icons-material/Abc';
import BluetoothSearchingIcon from '@mui/icons-material/BluetoothSearching';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { v4 as uuid } from 'uuid';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {apiRequest} from "../apis.js";
import "./Home.css";
import {DrawMiniBoard,DrawCountryMiniBoard} from "../Scoreboard.js";
import {DrawHomeScoreboard,DrawHomeCountryBoard} from "../components/Home/Scoreboard.js"
import {LastWays} from "../components/Home/LastWays.js"
import "../flags16.css";
import {theme} from '../theme/index.js'
import {ChallengeCard} from '../components/Home/ChallengeCard.js'
import {TotalUsers} from '../components/Home/TotalUsers.js'
import {ChallengeMini as Challenge} from "../components/Home/ChallengeMini.js";
//import {GroupInfo} from "../GroupInfoMini.js";

import {feedbacks} from "../__mocks__/feedbacks.js";




const Header = () => {
  return (
    <div className="" style={{marginRight:0,marginLeft:0, marginBottom:0,height:'250px'}}>          
    <div className="innerSecion" style={{background:'rgb(80, 72, 229)'}}>
      <div className="textAndImage" style={{background:'rgb(80, 72, 229)'}}>
      <div style={{marginTop:'-23px',marginRight:'1em'}} className="leftImage"><img src="https://www.interway.ai/wp-content/uploads/2022/03/code_trans-1-300x300.png" className="leftImageImage"></img></div>
        <div className="textSection"><h1>Challenge yourself with AI-guided research questions</h1>

        <h3 style={{marginTop:'1em'}}>Interway.AI combines challenges inspired by real interviews and jobs, with a virtual interviewer that accompanies you during the questions. <b>It's a different exprience, just click below to start.</b></h3>
        <div style={{textAlign:'center',marginBottom:'0em',marginTop:'0em'}}>
          <Link to="/challenges" className="startButton" style={{textTransform:'none'}} variant="contained">Start here</Link>
        </div>
      </div>
      <div className="rightImage">
        {false && <img src="https://www.interway.ai/wp-content/uploads/2022/03/code_trans-1-300x300.png" className="rightImageImage"></img>}
        

      </div>
    </div>
 </div></div>)
}

const FeedBacks = () => {
  return (
    <div className="" style={{width:'100%',alignItems:'center',justifyContent:'space-around',minWidth:'300px',marginTop:'2em'}}>
    <div>
     <div id="content">
         <div className="testimonial">
           <blockquote>
           {feedbacks[0].text}
           </blockquote>
           <div></div>
           <p style={{color:'black'}}>
          <a href={feedbacks[0].url} target='_blank'>{feedbacks[0].source} </a>
           </p>
         </div>
     </div></div>
     
     <div id="content">
         <div className="testimonial">
           <blockquote>
           {feedbacks[1].text}
           </blockquote>
           <div></div>
           <p style={{color:'black'}}>
              <a href={feedbacks[1].url} target='_blank'>{feedbacks[1].source} </a>
           </p>
         </div>
     </div>

     <div id="content">
         <div className="testimonial">
           <blockquote>
           {feedbacks[2].text}
           </blockquote>
           <div></div>
           <p style={{color:'black'}}>
            {feedbacks[2].source} 
           </p>
         </div>
     </div>
     <div style={{textAlign:'right'}}><Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
          variant="text"
          onClick={()=>document.location="/feedback"}
        >
          Write us a feedback
        </Button>
        </div>
    
     </div>
    
              
  )
}
const Copyright = ({totalEnters,totalUsers,totalSolvers}) => {
  return (
    <div className="miniBox" style={{display:'flex',marginBottom:'0.5em',fontSize:'0.9em',justifyContent:'space-evenly'}}>
    <div style={{padding:5}}>Copyright © <a href="https://interway.ai">interway.ai</a></div>
    <div style={{padding:5}}>{totalEnters} users has entered the site since April 2022, {totalUsers} logged-in, {totalSolvers} solved at least one challege. Updated every hour.</div>
  </div>
  )
}



const challenges = [
  {
    title:"Unknown Game",
    media:"",
    description:"Research on a binary file, using only linux commands. Can you read its content?",
    svg:<KeyIcon />,
    solvers: 56,
    extra: "Most trusted by recruiters"
  },
{
  title:"Guess",
  media:"",
  description:"Can you guess the random number? Exploit stack overflow in C.",
  svg:<BugReportIcon />,
  solvers: 10,
  extra: "Most rated"
},

{
  title:"Word count",
  media:"",
  description:"Bash introduction for newbies",
  svg:<AbcIcon />,
  solvers: 201,
  extra: "Most popular"
}


]
export function Home() {
    const [error, setError] = useState(null);
    const [lastWays,setLastWays] = useState([]);
    const [scoreLoading,setScoreLoading] =  useState(false);
    const [totalEnters,setTotalEnters] = useState(2120);
    const [totalUsers,setTotalUsers] = useState(420);
    const [totalSolvers,setTotalSolvers] = useState(300);
    const [lastCorrectWays,setLastCorrectWays] = useState(null);
    const [randFeed,setRandFeed] = useState(Math.floor(Math.random() * 2));

    useEffect(() => {
      setScoreLoading(true);
        getLastWays();
    },[]);

    const getLastWays = () => {
        let dref = "";
        if ("document" in window && "referrer" in window.document) dref = window.document.referrer;
        apiRequest("post","/apis.php?action=get_last_ways&token=play_&playground",{},{ref:dref}, (res)=>{
                                if (!error) setError(res["error"] ?? null);
                                if ("obj" in res) {
                                  setLastWays(res["obj"]["last_ways"] ?? []);
                                  setLastCorrectWays(res["obj"]["last_correct"] ?? []);
                                  setTotalEnters(res["obj"]["total_enters"]);
                                  setTotalUsers(res["obj"]["total_users"]);
                                  setTotalSolvers(res["obj"]["total_solvers"]);
                                  setScoreLoading(false);
                                }
          });
      }

    return (
        <div style={{background:'#F9FAFC'}}>{error} 
           <ThemeProvider theme={theme}>
           {false && <Header />}
           <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            sm={12}
            xl={8}
            xs={12}
          >
            <Header /> 
          </Grid>
          <Grid
            item
            lg={4}
            sm={12}
            xl={4}
            xs={12}
          >
           
           <Challenge agreed={true} challenge_name={"word_count"} token={"play_"} refresh={()=>{}} onError={()=>{}} lastMessage={"hi"}/>
          </Grid>
          
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <DrawHomeScoreboard /> 
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <DrawHomeCountryBoard /> 
          </Grid>

        
          {challenges.map((challenge,index) => (
              <Grid
                item
                key={index}
                lg={4}
                md={4}
                xs={12}
              >
                <ChallengeCard challenge={challenge} />
              </Grid>  ))}

              <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <LastWays ways={lastWays} />
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          >
            <FeedBacks />
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <TotalUsers total={totalEnters} up={51} title={"visitors"} iconType={1}/>
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <TotalUsers total={totalUsers} up={43} title={"Logged-in"} iconType={0}/>
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <TotalUsers total={totalSolvers} title={"solvers"} up={36} iconType={2}/>
          </Grid>

          </Grid></Container>


          <div className="miniBox" style={{display:'flex',marginBottom:'0.5em',fontSize:'0.9em',justifyContent:'space-evenly'}}>
            <div style={{padding:5}}>Copyright © <a href="https://interway.ai">interway.ai</a></div>
   
           </div>

       
        </ThemeProvider>
        </div>
    )
}