
import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import {useParams} from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TextField from '@mui/material/TextField';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "./Playground.css";

import {helpIcon,lockIcon} from "./svgs.js";
import {DrawMiniBoard,DrawCountryMiniBoard} from "./Scoreboard.js";
import "./flags16.css";
import {scoreboard_data,last_ways_data} from "./data.js";

const feedbacks = [
{source:"Reddit, April 2022" ,
url:"https://www.reddit.com/r/developersIndia/comments/tqvrs6/interactive_platform_for_learning_challenging/",
text:"I absolutely love it, already solved two questions, will recommend this to everyone. Can you add more questions. I really think you have a winner here"},
{source:"Reddit, April 2022",
url:"https://www.reddit.com/r/developersIndia/comments/tqvrs6/interactive_platform_for_learning_challenging/",
text:"Loved it! Never saw something like this before. Great Product! Keeping working on it! Would love to see more questions."}
];

const pastTimeInStringMinutes = (time) => {
  if (!time) return "";
  let now = new Date().getTime();
  let user_time = new Date(time).getTime();
  if(!user_time) user_time =  new Date(time.replace(" ","T")).getTime();
  let  diff = now - user_time;
  let Difference_In_Days = Math.round(diff / (1000 * 3600 * 24));
  let Difference_In_Hours = Math.round(diff / (1000 * 3600));
  let Difference_In_Mintues = Math.round(diff / (1000 * 60));
  if (Difference_In_Mintues == 0) return (<div style={{display:'flex',justifyContent: 'center'}}><div>Right now</div> <div className="playCircle"></div></div>)
  if (Difference_In_Days > 0) {
    return `${Difference_In_Days} days ago`;
  }
  if (Difference_In_Hours > 0) {
    return `${Difference_In_Hours} hours ago`;
  }
  return `${Difference_In_Mintues} minutes ago`;
  

}




export function Playground() {
    const [mailInput, setMailInput] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [company, setCompany] = useState("");
    const [challenges,setChallenges] = useState([]);
    const [contactYou, setContactYou] = useState(null);
    const [error, setError] = useState(null);
    const [userHash,setUserHash] = useState(null);
    const [myScoreRow,setMyScoreRow] = useState(null);
    const [scoreboard,setScoreboard] = useState([]);
    const [username,setUsername] = useState("");
    const [lastWays,setLastWays] = useState([]);
    const [lastCorrectWays,setLastCorrectWays] = useState([]);
    const [scoreLoading,setScoreLoading] =  useState(false);
    const [totalEnters,setTotalEnters] = useState(2120);
    const [totalUsers,setTotalUsers] = useState(420);
    const [totalSolvers,setTotalSolvers] = useState(300);
    const [randFeed,setRandFeed] = useState(Math.floor(Math.random() * 2));

    useEffect(() => {
      setScoreLoading(true);

        getLastWays();
    },[]);


    const getLastWays = () => {
      let res = last_ways_data;

      setLastWays(res["obj"]["last_ways"] ?? []);
      setLastCorrectWays(res["obj"]["last_correct"] ?? []);
      setTotalEnters(res["obj"]["total_enters"]);
      setTotalUsers(res["obj"]["total_users"]);
      setTotalSolvers(res["obj"]["total_solvers"]);
      setScoreLoading(false);
}

    return (
  <div>{error} 


    <div className="section"> 
         
    <div className="innerSecion">
      <div className="textAndImage">
        <div className="textSection">
          <h1>AI-guided challenges platform for <strong><span style={{color:'#ffccae'}}>learning</span>, <span style={{color:'#ffccae'}}>practicing</span> and <span style={{color:'#ffccae'}}>hiring</span>.
          </strong></h1>
          <h2 style={{textAlign:'left'}}>Hire / get hired by proven skills.</h2>
          <h3>Interway.AI combines challenges inspired by real interviews and jobs, with a virtual interviewer that accompanies you during the questions. <b>It's a different exprience, just click below to start.</b></h3> 

          <div style={{textAlign:'center',marginBottom:'1em',marginTop:'1em'}}>
            <Link to="/challenges" className="startButton" style={{textTransform:'none'}} variant="contained">Start here</Link>
          </div>
        </div>
      <div className="rightImage">
        <img src="https://www.interway.ai/wp-content/uploads/2022/03/code_trans-1-300x300.png" className="rightImageImage"></img>
      </div>
    </div>


  </div>
 </div>

       

  <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}> 
    <div className="miniBox" style={{width:'47%',minWidth:'300px'}}>
            
      <DrawMiniBoard />
    </div>
    <div className="miniBox" style={{width:'47%',minWidth:'300px'}}>
      <DrawCountryMiniBoard />
    </div>
  </div>
  <div>
    {scoreLoading ? (<span>Loading...<CircularProgress color="inherit" /></span>) : null}
  </div>
            
  <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}> 
    <div className="miniBox" style={{width:'47%',minWidth:'300px'}}>
              <div className="miniBoxTitle" style={{background:'#465068',color:'white',fontSize:'1.2em',padding:'0.1em',}}>Last activties <div className="playCircle" style={{marginTop:'0.3em'}}></div> </div>
              <table className="lastActiveTable" style={{marginTop:'0.5em'}}>
              <tbody>
              {lastWays.map((way,index) => (
                <tr key={index}><td style={{textAlign:'left'}}>
                  <span style={{fontStyle:'italic',fontWeight:550}}>{way.correct && <EmojiEventsIcon />}
                  
                  <a href={"/profile/"+way.id}>{way.public_username}</a></span> 
                  {way.challenge_name=="view_group" && (<span> is watching the scoreboard</span>) }
                  {way.challenge_name!="view_group" && !way.correct && (<span> is trying to solve <span style={{fontStyle:'italic'}}>{way.challenge_name}</span></span>) }
                  {way.challenge_name!="view_group" && way.correct && (<span> solved <span style={{fontStyle:'italic'}}>{way.challenge_name}</span></span>) }
                </td>
                <td>{pastTimeInStringMinutes(way.time)}</td></tr>))}
              </tbody></table>
            </div>

            <div className="" style={{ marginTop:'2em',marginRight:'0.5em',display:'',width:'50%',alignItems:'center',justifyContent:'space-around',minWidth:'300px'}}>
<div>
 <div id="content">
     <div className="testimonial">
       <blockquote>
       {feedbacks[0].text}
       </blockquote>
       <div></div>
       <p style={{color:'black'}}>
      <a href={feedbacks[0].url} target='_blank'>{feedbacks[0].source} </a>
       </p>
     </div>
 </div></div>
 
 <div id="content">
     <div className="testimonial">
       <blockquote>
       {feedbacks[1].text}
       </blockquote>
       <div></div>
       <p style={{color:'black'}}>
          <a href={feedbacks[1].url} target='_blank'>{feedbacks[1].source} </a>
       </p>
     </div>
 </div>

 </div>

            </div>
            <div className="miniBox" style={{display:'flex',marginBottom:'0.5em',fontSize:'0.9em',justifyContent:'space-evenly'}}>
              <div style={{padding:5}}>Copyright © <a href="https://interway.ai">interway.ai</a></div>
              <div style={{padding:5}}>{totalEnters} users has entered the site since April 2022, {totalUsers} logged-in, {totalSolvers} solved at least one challege. Updated every hour.</div>
             
              </div>

        </div>
    )
}