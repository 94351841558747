
import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import {useParams} from "react-router-dom";


import Tooltip from '@mui/material/Tooltip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import PublicIcon from '@mui/icons-material/Public';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {getCountryName} from "./Countries.js";
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import "./Playground.css";
import "./Scoreboard.css";
import {helpIcon,lockIcon} from "./svgs.js";
import {scoreboard_data,country_scoreboard_data} from "./data.js"
import "./flags16.css";
import "./flags32.css";

const pastTimeInString = (time) => {
    if (!time) return "";
    let now = new Date().getTime();
    let user_time = new Date(time).getTime();
    if(!user_time) user_time =  new Date(time.replace(" ","T")).getTime();
    let  diff = now - user_time;
    let Difference_In_Days = Math.round(diff / (1000 * 3600 * 24));
    if (Difference_In_Days == 0) return (<div style={{display:'flex',justifyContent: 'center'}}><div>Today</div> <div className="playCircle"></div></div>)
    return `${Difference_In_Days} days ago`;

}



export const DrawMiniBoard = () => {
  const [scoreboard,setScoreboard] = useState(null);
  const [scoreLoading,setScoreLoading] = useState(false);
  const [error,setError] = useState(false);
  let me = 0;

  useEffect(()=>{
    getDashboard();
  },[]);
  const getDashboard = () => {
    let res = scoreboard_data;
    setScoreboard(res["obj"]);
  }

    return (
        <TableContainer component={Paper}>
          <div className="miniBoxTitle" style={{background:'#465068',color:'white',fontSize:'1.2em',padding:'0.5em',lineHeight:''}}>
          {scoreLoading ? (<span>Loading...<CircularProgress color="inherit" /></span>) : null}
           <SportsScoreIcon /> Users scoreboard</div>
        <Table aria-label="collapsible table"   sx={{
    [`& .${tableCellClasses.root}`]: {
      borderBottom: "none"
    }
  }}>
          <TableHead>
            <TableRow sx={{background:'#dedede'}}>
             
         
              <TableCell align="center" className="tableTrFont"><div style={{textAlign:'left',paddingLeft:'30%',color:'rgb(209 29 29)'}}>User</div></TableCell>
              <TableCell align="center" className="tableTrFont"><div style={{textAlign:'center',paddingRight:'2em',color:'rgb(209 29 29)'}}>Score</div></TableCell>
         </TableRow>
          </TableHead>
          <TableBody>

              {scoreboard && scoreboard.slice(0,5).map((user,index) => (
                <TableRow  key={index} sx={[{ '& > *': { borderBottom: 'unset' }} ,{'background': index%2?"white":"#f6f4f6"},{'&:hover': {background: '#ebebeb'}}]}>

      
                
                <TableCell align="center" className="tableFont">
                <div style={{textAlign:'center',paddingLeft:'20%'}}>
                
                <div style={{textAlign:'left'}}>
               <span style={{marginRight:'2em',fontWeight:600}}>{user.place}</span>
                {user.country &&  (<span className="f32" style={{marginRight:'0.5em'}}>
                  <span style={{verticalAlign:'middle'}} className={"flag " + user.country.toLowerCase()} ></span>
                </span>)}
                  <a href={`./profile/${user.you ? "" : user.user_id}`}>
                    <span style={{fontWeight:600,letterSpacing:'0.1em',fontSize:'1.2em',color:index==0?"#2f4e9b":"blue"}} >{user.username} {user.username.startsWith("Anonymous") ? (<Tooltip title="This user hasn't signed in yet">{helpIcon()}</Tooltip>) : null}</span>
                    </a>
                  {user.award && (<Tooltip title="Award for sharing his solution with the community"><WorkspacePremiumIcon sx={{color:'green'}}/></Tooltip>)}
                </div></div>
                  </TableCell>
                <TableCell align="center" className="tableFont" style={{fontWeight:600}}><div style={{fontWeight:500,fontSize:'1.3em',textAlign:'center',paddingRight:'2em'}} className="scoreFont">{user.score}</div></TableCell>
  
              </TableRow>
            
              ))}

              </TableBody>
              </Table>
            </TableContainer>
    )
}

export const DrawCountryMiniBoard = () => {
  const [scoreboard,setScoreboard] = useState(null);
  const [scoreLoading,setScoreLoading] = useState(true);
  const [error,setError] = useState(false);
  let me = 0;

  useEffect(()=>{
    getDashboard();
  },[]);
  const getDashboard = () => {
    let res = country_scoreboard_data;
  setScoreboard(res["obj"]);

  }

    return (
        <TableContainer component={Paper} >
          <div className="miniBoxTitle" style={{background:'#465068',color:'white',fontSize:'1.2em',padding:'0.5em',}}>
            <PublicIcon/> Country scoreboard</div>
        <Table aria-label="collapsible table" 
         sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none"
          }
        }} style={{textAlign:'center',margin:'auto'}}>
          <TableHead>
            <TableRow sx={{background:'#dedede'}}>
             
         
              <TableCell align="center" className="tableTrFont"><div style={{textAlign:'left',paddingLeft:'30%',color:'rgb(209 29 29)'}}>Country</div></TableCell>
              <TableCell align="center" className="tableTrFont"><div style={{textAlign:'center',paddingRight:'2em',color:'rgb(209 29 29)'}}>Total score</div></TableCell>
         </TableRow>
          </TableHead>
          <TableBody>

              {scoreboard && scoreboard.slice(0,5).map((user,index) => (
                <TableRow  key={index} sx={[{ '& > *': { borderBottom: 'unset' }} ,{'background': index%2?"white":"#f6f4f6"},{'&:hover': {background: '#ebebeb'}}]}>

      
                
                <TableCell align="center" className="tableFont">
                <div style={{textAlign:'left',paddingLeft:'20%'}}>
                <div style={{textAlign:'left'}}>
               <span style={{marginRight:'2em',fontWeight:600}}>{index+1}</span>
                {user.country &&  (<span className="f32" style={{marginRight:'0.5em'}}>
                  <span style={{verticalAlign:'middle'}} className={"flag " + user.country.toLowerCase()} ></span>
                </span>)}
                  <span style={{fontSize:'1.2em',letterSpacing:'0.1em',fontWeight:600}}>{getCountryName(user.country)}</span>
                  {user.award && (<Tooltip title="Award for sharing his solution with the community"><WorkspacePremiumIcon sx={{color:'green'}}/></Tooltip>)}
                </div></div>
                  </TableCell>
                <TableCell align="center" className="tableFont" style={{fontWeight:600}}><div style={{fontWeight:500,fontSize:'1.3em',textAlign:'center',paddingRight:'2em'}} className="scoreFont">{user.score}</div></TableCell>
  
              </TableRow>
            
              ))}

              </TableBody>
              </Table>
            </TableContainer>
    )
}

const DrawPartialScoreBoard = (users_score) => {
  const [upCount,setUpCount] = useState(0);
  const [downCount,setDownCount] = useState(0);
  let me = 0;
  users_score.forEach((element,index) => {
    if (element.you) me = index;
  });
  console.log(me);
    return (
        <TableContainer component={Paper}>
        
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
             
              <TableCell align="center" className="tableTrFont">#</TableCell>
              <TableCell align="center" className="tableTrFont">User</TableCell>
              <TableCell align="center" className="tableTrFont">Score</TableCell>
              <TableCell align="center" className="tableTrFont">Last solve</TableCell>
              <TableCell align="center" className="tableTrFont">Last active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {(me-2-upCount) > 0 ? (<TableRow sx={[{ '& > *': { borderBottom: 'unset' }}]}><TableCell colspan={5} align="center" className="tableFont">
            <button onClick={()=>setUpCount(v=>v+2)}>...</button></TableCell></TableRow>) : null}
              {users_score.slice(Math.max(0,me-2-upCount),downCount+me+3).map((user,index) => (
                <TableRow sx={[{ '& > *': { borderBottom: 'unset' }} ,{'background':user.you ? "#ffffc4" : "white"},{'&:hover': {background: '#ebebeb'}}]}>

      
                <TableCell align="center" className="tableFont">{user.place}  {user.place_diff  > 0 ? null : null}</TableCell>
                <TableCell align="center" className="tableFont">
                {user.country &&  (<span class="f32" style={{marginRight:'0.5em'}}>
                  <span style={{verticalAlign:'middle'}} class={"flag " + user.country.toLowerCase()} ></span>
                </span>)}
                  <a href={`./profile/${user.user_id}`}>{user.username} {user.username.startsWith("Anonymous") ? (<Tooltip title="This user hasn't signed in yet">{helpIcon()}</Tooltip>) : null}</a>
                {user.award && (<Tooltip title="Award for sharing his solution with the community"><WorkspacePremiumIcon sx={{color:'green'}}/></Tooltip>)}
                </TableCell>
                <TableCell align="center" className="tableFont">{user.score}</TableCell>
                <TableCell align="center" className="tableFont">{pastTimeInString(user.last_solve_time)} {user.last_challenge}</TableCell>
                <TableCell align="center" className="tableFont">{pastTimeInString(user.last_way_time)}</TableCell>
  
              </TableRow>
            
              ))}
              <TableRow ><TableCell colspan={5} align="center" className="tableFont">
              <button onClick={()=>setDownCount(v=>v+2)}>...</button></TableCell></TableRow>
              </TableBody>
              </Table>
            </TableContainer>
    )
}


const DrawScoreBoard = (users_score) => {

  return (
      <TableContainer component={Paper}>
     
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
           
            <TableCell align="center" className="tableTrFont">#</TableCell>
            <TableCell align="center" className="tableTrFont">User</TableCell>
            <TableCell align="center" className="tableTrFont">Score</TableCell>
            <TableCell align="center" className="tableTrFont">Last active</TableCell>
            <TableCell align="center" className="tableTrFont">Free text <Tooltip title="You can write here whatever you want. You can also advertise your own project.">{helpIcon()}</Tooltip></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

            {users_score.map((user,index) => (
              <TableRow sx={[{ '& > *': { borderBottom: 'unset' }} ,{'background':user.you ? "#ffffc4" : "white"},{'&:hover': {background: '#ebebeb'}}]}>

    
              <TableCell align="center" className="tableFont">{user.place} {user.place_diff  > 0 ? null : null}</TableCell>
              <TableCell align="center" className="tableFont">
              <div style={{display:'flex'}}>
              {user.country &&  (<span class="f32" style={{marginRight:'0.5em'}}>
                <span style={{verticalAlign:'middle'}} class={"flag " + user.country.toLowerCase()} ></span>
              </span>)}
                <a href={`./profile/${user.user_id}`}>
                  <span style={{display:'flex'}}>
                    {user.username.startsWith("Anonymous") ? null : (<Avatar  sx={{ width: 24, height: 24 }} >{user.username[0]}{user.username[1]}</Avatar>)}
                    <span style={{marginTop:'8px',marginLeft:'0.7em'}}> 
                      {user.username} {user.username.startsWith("Anonymous") && (<Tooltip title="This user hasn't signed in yet">{helpIcon()}</Tooltip>)}
                    </span>
                  </span>
                </a>
                
                {user.award && (<Tooltip title="Award for sharing his solution with the community"><WorkspacePremiumIcon sx={{color:'green'}}/></Tooltip>)}
                </div>
                </TableCell>
              <TableCell align="center" className="tableFont">{user.score}</TableCell>
              <TableCell align="center" className="tableFont">{pastTimeInString(user.last_place_read)}</TableCell>
              <TableCell align="center" className="tableFont"><div style={{textAlign:'center',color:'grey',fontSize:'0.9em',width:'10em',overflowWrap: 'break-word',margin:'auto'}}>{user.freeText}</div></TableCell>

            </TableRow>
          
            ))}

            </TableBody>
            </Table>
          </TableContainer>
  )
}
export function Scoreboard(props) {
  const [mailInput, setMailInput] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [company, setCompany] = useState("");
  const [challenges,setChallenges] = useState([]);
  const [contactYou, setContactYou] = useState(null);
  const [error, setError] = useState(null);
  const [userHash,setUserHash] = useState(null);
  const [scoreboard,setScoreboard] = useState([]);
  const [username,setUsername] = useState("");
  const [lastWays,setLastWays] = useState([]);
  const [lastCorrectWays,setLastCorrectWays] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    getScoreboard();
  },[]);

  const getScoreboard = () => {
    let res =scoreboard_data;
    setScoreboard(res["obj"]);
    }


  return (
      <div>{error} 
      {loading ? (<span>Loading...<CircularProgress color="inherit" /></span>) : null}
      <div className="miniBox">
          <div><div className="miniBoxTitle" style={{background:'#465068',color:'white',fontSize:'1.2em',padding:'0.3em',}}>Scoreboard</div>
          {props.part ? DrawPartialScoreBoard(scoreboard) : DrawScoreBoard(scoreboard)}
          </div>
          </div>
      </div>
  )
}